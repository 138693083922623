import React from 'react';
import styled from 'styled-components';

const PolaroidWrapper = styled.div`
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        transition: all 0.35s;
    }

    > img {
        max-width: 100%;
        height: auto;
    }
`;

const PolaroidCaption = styled.div`
    font-size: 1.8rem;
    text-align: center;
    line-height: 2em;
    color: #000;
`;

const PolaroidItem = styled.div`
    display: inline-block;
    margin: 0 2rem;
    filter: grayscale(100%);

    &:hover {
        filter: none;
        transform: scale(1, 1) rotate(0deg) !important;
        transition: all 0.35s;
    }
`;

const Polaroid = () => (
    <PolaroidItem>
        <PolaroidWrapper>
            <img
                loading="lazy"
                src="/img/art-head.png"
                alt="Arthur Kay || Secure Web Solutions LLC" />

            <PolaroidCaption>Arthur Kay, CEO</PolaroidCaption>
        </PolaroidWrapper>
    </PolaroidItem>
);

export default Polaroid;