import React from 'react';
import Page, { SECTION, HEADING, ROW, TEXT, CONTENT, CONTENT_LI } from '../components/Page';

import Testimonial from '../components/Testimonial';
import Polaroid from '../components/Polaroid';

const Home = () => (
  <Page>
    <ROW className="row">
      <section className="col-md-8 col-sm-12">
        <HEADING>Creating <TEXT>Solutions</TEXT> with <TEXT>Software</TEXT> 🔥</HEADING>

        <CONTENT>
          Drive technical innovation and development with experienced leadership on a flexible basis.
        </CONTENT>

        <CONTENT>
          Leverage my Silicon Valley experience and technical expertise to level-up, solve problems and move faster.
        </CONTENT>

        <CONTENT>
          Think outside the box. Let's build something amazing together!
        </CONTENT>

        <CONTENT>
          <a className="btn btn-primary" href="/contact"><em>How can I help you?</em></a>
        </CONTENT>
      </section>

      <section className="col-md-4 col-sm-12">
        <Polaroid />
      </section>
    </ROW>

    <SECTION>
      <section>
        <HEADING>What could you do with  <span style={{ color: "#40b1e8" }}>more time</span>? ⏰</HEADING>

        <CONTENT>
          Hi, I'm Art 👋
        </CONTENT>

        <CONTENT>
          I'm an entrepreneur with 20 years of experience as a software engineer.
        </CONTENT>

        <CONTENT>
          By day, I write software for tech startups and consult for companies of all sizes.
        </CONTENT>

        <CONTENT>
          By moonlight, I work with small businesses on special projects and odd-jobs.
          Small business tech strategy, engineering leadership, and hands-on-keyboard implementation.
        </CONTENT>

        <CONTENT>
          Whether you have a specific initiative or require retained part-time expertise,
          I can help you hit your goals without hitting your bank account.
        </CONTENT>

        <CONTENT>
          <a className="btn btn-primary" href="/contact"><em>Let's talk!</em></a>
        </CONTENT>
      </section>
    </SECTION>

    <SECTION>
      <section>
        <hr />

        <HEADING><span style={{ color: "#40b1e8" }}>Accelerate</span> your team 🚀</HEADING>

        <CONTENT>
          Need an extra set of hands on your engineering team? I can kickstart an MVP or embed into your existing codebase.
        </CONTENT>

        <ul>
          <CONTENT_LI>Less onboarding.</CONTENT_LI>
          <CONTENT_LI>Faster results.</CONTENT_LI>
          <CONTENT_LI>Proven success.</CONTENT_LI>
        </ul>

        <CONTENT>
          Here's what my customers say about me:
        </CONTENT>
      </section>
    </SECTION>

    <ROW className="row">
      <section className="col-md-4 col-sm-12">
        <Testimonial>
          <CONTENT>
            "Worked with Art for over 6 months on frontend building and design. Art delivered a great product and was attentive to
            our needs and made all necessary changes in a very timely manner.
          </CONTENT>

          <CONTENT>
            He also provided critical expertise in the cyber security space as we launched our product.
          </CONTENT>

          <CONTENT>
            I highly recommend Art and do expect to work with him again in the future."
          </CONTENT>
        </Testimonial>
      </section>

      <section className="col-md-4 col-sm-12">
        <Testimonial>
          <CONTENT>
            "We hired Secure Web Solutions LLC to perform penetration testing on our system, and I found the process to be very smooth and
            highly professional from start to finish.
          </CONTENT>

          <CONTENT>
            The report we received was extremely thorough and they worked closely with us afterward to make sure we understood the results
            and how to address the issues found.
          </CONTENT>

          <CONTENT>
            I highly recommend their services."
          </CONTENT>
        </Testimonial>
      </section>

      <section className="col-md-4 col-sm-12">
        <Testimonial>
          <CONTENT>
            "Arthur had an amazing ability to juggle multiple projects and deliver on all of them. He was able to drop into our company and
            make an impact day one.
          </CONTENT>

          <CONTENT>
            He didn't need much supervision, and was always quick to reply to questions. He was very intelligent and brought
            thoughtful insights to design discussions.
          </CONTENT>

          <CONTENT>
            I think Arthur is a great engineer and I would love to work with him again in the future."
          </CONTENT>
        </Testimonial>
      </section>
    </ROW>

    <SECTION>
      <section>
        <CONTENT>
          Past customers include:
        </CONTENT>

        <img src="/img/customers/customer-logos.png" alt="" className="img-fluid" />
      </section>
    </SECTION>

    <SECTION>
      <section>
        <hr />

        <HEADING>I take security <span style={{ color: "#40b1e8" }}>seriously</span> 🔐 </HEADING>

        <CONTENT>
          Secure code is <em>quality code</em>.
        </CONTENT>

        <CONTENT>
          I have earned a variety of cybersecurity certifications in my pursuit of high-quality sofware:
        </CONTENT>

      </section>
    </SECTION>

    <ROW className="row">
      <section className="col-md-4 col-sm-12">
        <img src="/img/icons/cissp-logo.png" alt="ISC(2) CISSP" className="img-fluid" />
      </section>

      <section className="col-md-4 col-sm-12">
        <img src="/img/icons/CSAP.jpg" alt="CompTIA CSAP" className="img-fluid" />
        <img src="/img/icons/CySA.png" alt="CompTIA CySA+" className="img-fluid" />
        <img src="/img/icons/SecurityPlus.jpg" alt="CompTIA Security+" className="img-fluid" />
      </section>

      <section className="col-md-4 col-sm-12">
        <img src="/img/icons/AWS-Certified_Cloud-Practitioner.png" alt="AWS Cloud Practitioner" className="img-fluid" />
        <img src="/img/icons/AWS-Certified_Security_Specialty.png" alt="AWS Security Specialty" className="img-fluid" />
      </section>
    </ROW>
  </Page >
);

export default Home;
